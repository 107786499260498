<template>
    <el-container class="content">
      <el-header class="header">
        <span class="left el-icon-arrow-left" @click="returnOn"></span>
        {{titleType}}
      </el-header>
      <el-main class="main">
        <div class="info">
          <div class="title">
            基本信息
          </div>
          <el-form label-position="left" label-width="100px" :model="serform">
            <el-form-item label="服务名称">
              <el-input v-model="serform.name" placeholder="输入服务名称"></el-input>
            </el-form-item>
  
            <el-form-item label="服务分类">
              <div @click="serviceClassClick">
                <el-input v-model="form.serviceClass" readonly suffix-icon="el-icon-arrow-right" placeholder="选择服务分类">
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="服务时长(分钟)">
              <el-input v-model="serform.hour" placeholder="输入服务时长"></el-input>
            </el-form-item>
            <el-form-item label="单价">
              <el-input v-model="serform.original_price" placeholder="最多保留小数点后两位"></el-input>
            </el-form-item>
            <el-form-item label="会员价">
              <el-input v-model="serform.vip_price" placeholder="最多保留小数点后两位"></el-input>
            </el-form-item>
            <el-form-item label="服务人员">
              <div @click="serviceBrandClick">
                <el-input v-model="form.serviceBrand" readonly suffix-icon="el-icon-arrow-right" placeholder="选择服务人员">
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="热门服务">
              <el-switch v-model="serform.stat" :active-value="1" :inactive-value="0" active-color="#409eff" inactive-color="#cccccc">
              </el-switch>
            </el-form-item>
          </el-form>
        </div>
        <div class="info add-set">
          <div class="title">
            更多设置
          </div>
          <div class="mark-ipt">
            <el-form label-position="left" label-width="100px" :model="form">
              <el-form-item label="是否赠送积分">
                <el-switch v-model="serform.zscon" :active-value="1" :inactive-value="0" active-color="#409eff" inactive-color="#cccccc">
                </el-switch>
              </el-form-item>
              <el-form-item label="赠送分值">
                <el-input v-model="serform.zsinteg" placeholder="输入积分值"></el-input>
              </el-form-item>
              <el-form-item label="是否提成">
                <div :class="serform.draw===1?'box-btn box-btn1':'box-btn'" @click="serform.draw=1">
                  固定提成
                </div>
                <div :class="serform.draw===2?'box-btn box-btn1':'box-btn'" @click="serform.draw=2">
                  比例提成
                </div>
                <div :class="serform.draw===3?'box-btn box-btn1':'box-btn'" @click="serform.draw=3">
                  不提成
                </div>
              </el-form-item>
              <el-form-item label="提成金额">
                <el-input v-model="serform.drawnum" placeholder="输入提成金额"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="info add-set">
          <div class="title">
            服务介绍
          </div>
          <div class="mark-ipt">
            <el-input type="textarea" :rows="3" resize="none" placeholder="详细介绍信息" v-model="serform.introduce" maxlength="300"
              show-word-limit>
            </el-input>
          </div>
        </div>
        <div style="width:100%;height:100px;"></div>
        <div class="footer">
          <el-button type="primary" round @click="addServer">保存</el-button>
        </div>
      </el-main>
      <!-- 服务分类 dialog -->
      <el-dialog class="serviceClassDialog dialog" center :visible.sync="serviceClassShow" width="40%"
        :before-close="handleClose" :show-close="false">
        <template slot="title">
          <div class="box">
            <div class="dialogTitle">
              <div class="left" @click="jumpManageAttr">分类管理</div>
              <div class="center">选择服务分类</div>
              <div class="right el-icon-close" @click="handleClose">
              </div>
            </div>
            <div class="dialogSearch">
              <el-input clearable placeholder="输入服务分类名称搜索" prefix-icon="el-icon-search" v-model="serviceClassSearchVal"
                @keyup.enter.native="serviceClassSearchChange">
              </el-input>
              <el-button type="primary" @click="serviceClassSearchChange">搜索</el-button>
            </div>
          </div>
        </template>
        <el-container class="container">
          <el-main class="cell-list">
            <el-radio-group v-model="serform.servercate_id">
              <el-radio :label="item.id" v-for="(item,i) in item" :key="i" @change="changeRadio(item)">
                <div class="dialog-title">
                  {{item.name}}
                </div>
              </el-radio>
            </el-radio-group>
          </el-main>
        </el-container>
  
      </el-dialog>
      <!-- 服务人员 dialog -->
      <el-dialog class="serviceBrandDialog dialog" center :visible.sync="serviceBrandShow" width="40%"
        :before-close="handleClose" :show-close="false">
        <template slot="title">
          <div class="box">
            <div class="dialogTitle">
              <div class="left"></div>
              <div class="center">选择服务人员</div>
              <div class="right el-icon-close" @click="handleClose">
              </div>
            </div>
            <div class="dialogSearch">
              <el-input clearable placeholder="输入人员名称搜索" prefix-icon="el-icon-search" v-model="serviceBrandSearchVal"
                @keyup.enter.native="serviceBrandSearchChange">
              </el-input>
              <el-button type="primary" @click="serviceBrandSearchChange">搜索</el-button>
            </div>
          </div>
        </template>
        <el-container class="container">
          <el-main class="cell-list">
            <el-checkbox-group v-model="serviceClassRadio">
              <el-checkbox :label="item.id" v-for="(item,i) in item" :key="i" @change="changeRadio(item)">
                <div class="dialog-title">
                  {{item.realname}}
                </div>
              </el-checkbox>
            </el-checkbox-group>
          </el-main>
        </el-container>
        <div class="btn">
          <el-button type="primary" round @click="confirm">确定</el-button>
        </div>
      </el-dialog>
    </el-container>
  </template>
  
  <script>
    export default {
      data() {
        return {
          item: [],
          titleType: '',
          form: {
            serviceName: '',
            serviceNum: '',
            serviceClass: '',
            serviceTime: '',
            unitPrice: '',
            vipPrice: '',
            serviceBrand: '',
            hotService: true,
            // 更多设置
            isIntegral: true,
            integralNum: '',
            isCommission: 1,
            buyPrice: '',
            textarea: '',
          },
          serviceClassShow: false,
          serviceClassRadio: [],
          serviceClassSearchVal: '',
          serviceBrandShow: false,
          serviceBrandRadio: '',
          serviceBrandRadio1: [],
          serviceBrandSearchVal: '',
          serform:{
            name:'',//服务名称
            servercate_id:'',//分类id
            hour:'',//服务时长(分钟为单位，传纯数字)
            original_price:'',//售价
            vip_price:'',//会员价
            person:'',//服务人员（传id字符串）
            introduce:'',//服务介绍
            stat:'',//1是热门服务0不是热门服务
            zscon:'',//1赠送积分0不赠送
            zsinteg:'',//赠送分值
            draw:'',//1=固定,2=比例,3=不提成
            drawnum:'',//提成
          }
        }
      },
      mounted() {
        this.titleType = this.$route.query.type === 'edit' ? '编辑服务' : '添加服务'
        // console.log(JSON.parse(this.$route.query.info))
        this.serform = JSON.parse(this.$route.query.info)
        this.serform.hour = this.serform.hour.slice(0,-2)
        this.form.serviceClass = this.serform.servercate_id//分类名称
        this.$http.post('api/store/server/servercate').then(res =>{
            for(let i in res.data.data){
                if(this.form.serviceClass == res.data.data[i].name){
                    this.serform.servercate_id = res.data.data[i].id
                }
            }
          })
       let name = []
       this.serviceClassRadio = this.serform.person.split(",")
       for(let j in this.serviceClassRadio){
        this.serviceClassRadio[j] =Number(this.serviceClassRadio[j])
       }
       this.$http.post('api/store/user/manystoreperson',{
          type:1
        }).then(res =>{
            for(let k in res.data.data){
                for(let h in this.serviceClassRadio){
                    if(res.data.data[k].id == this.serviceClassRadio[h]){
                        name.push(res.data.data[k].realname)
                        this.serviceBrandRadio1.push(res.data.data[k].realname)
                    }
                }
            }
            this.form.serviceBrand = name.join()
        })
       if(this.serform.zscon == '是'){
        this.serform.zscon = 1
       }else{
        this.serform.zscon = 0
       }
       if(this.serform.stat == '是'){
        this.serform.stat = 1
       }else{
        this.serform.stat = 0
       }
       if(this.serform.draw == '固定'){
        this.serform.draw = 1
       }else if(this.serform.draw == '比例'){
        this.serform.draw = 2
       }else if(this.serform.draw == '不提成'){
        this.serform.draw = 3
       }
      },
      methods: {
        //修改服务
        addServer(){
          this.$http.post('api/store/server/editserver',this.serform).then(res =>{
            this.$message.success(res.data.msg)
          })
        },
        //服务人员列表
        manyList(){
          this.$http.post('api/store/user/manystoreperson',{
          type:1
        }).then(res =>{
            this.item = res.data.data
          })
        },
        //获取服务分类
        getServerClass(){
          this.$http.post('api/store/server/servercate').then(res =>{
            this.item = res.data.data
          })
        },
        // 返回上级
        returnOn() {
          this.$router.go(-1)
        },
        // 显示弹窗
        serviceClassClick() {
          this.getServerClass()
          this.serviceClassShow = true
        },
        serviceBrandClick() {
          this.manyList()
          this.serviceBrandShow = true
        },
        // 搜索
        serviceClassSearchChange() {
          if (this.serviceClassSearchVal.trim()) {
            console.log(this.serviceClassSearchVal.trim());
          } else {
            this.$message.error('搜索内容不能为空')
          }
        },
        // 搜索
        serviceBrandSearchChange() {
          if (this.serviceBrandSearchVal.trim()) {
            console.log(this.serviceBrandSearchVal.trim());
          } else {
            this.$message.error('搜索内容不能为空')
          }
        },
        // 选择弹窗
        changeRadio(item, typeName) {
          if (this.serviceClassShow) {
            this.serviceClassShow = false
            this.form.serviceClass = item.name
  
          } else if (this.serviceBrandShow) {
            if (this.serviceBrandRadio1.indexOf(item.realname) !== -1) {
              this.serviceBrandRadio1.splice(this.serviceBrandRadio1.indexOf(item.realname), 1)
            } else {
              this.serviceBrandRadio1.push(item.realname);
            }
          }
        },
        confirm() {
          if (this.serviceBrandShow) {
            this.serviceBrandShow = false
            this.serform.person = this.serviceClassRadio.join()
            this.form.serviceBrand = this.serviceBrandRadio1.join()
          }
        },
  
        // 关闭dialog
        handleClose() {
          this.serviceClassShow = false
          this.serviceBrandShow = false
  
        },
        jumpManageAttr() {
          this.$router.push({
            name: 'ServiceManageAttr',
          })
        },
        confirmSubmit() {
          console.log(this.form);
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          setTimeout(() => {
            this.$router.go(-1)
          }, 1000)
        }
  
      }
    };
  
  </script>
  
  <style lang="less" scoped>
    .content {
      height: calc(100vh);
    }
  
    // 头部
    .el-header {
      position: relative;
      z-index: 1;
      background-color: #fff;
      font-weight: bold;
      font-size: 16px;
      color: #333;
      height: 50px !important;
      line-height: 50px;
      text-align: center;
      border-bottom: 1px solid #e9e9e9;
  
      .left {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: #46a6ff;
        font-size: 24px;
      }
    }
  
    /deep/ .main.el-main {
      background-color: #f9f9f9;
      padding: 15px !important;
      min-height: calc(100vh);
  
      .info {
        background-color: #fff;
  
        .title {
          border-bottom: 1px solid #f2f2f2;
          padding: 12px;
          font-weight: bold;
          font-size: 14px;
          color: #666;
          margin-bottom: 10px;
        }
  
        .el-form {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          padding-bottom: 15px;
  
          .el-form-item {
            width: 48%;
            margin: 0 1%;
            border-bottom: 1px solid #f2f2f2;
  
            &:nth-last-child(1),
            &:nth-last-child(2) {
              border-bottom: none;
            }
  
            .el-form-item__label {
              font-size: 12px;
            }
  
            .el-input {
              .el-input__inner {
                border: none;
                text-align: right;
  
                &::placeholder {
                  font-size: 12px;
                }
              }
            }
  
            .el-form-item__content {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              height: 100%;
            }
  
            .el-checkbox-group {
              padding-right: 15px;
  
            }
  
            .el-date-editor {
              .el-input__inner {
                padding: 0 15px 0 0;
  
              }
  
              .el-input__prefix {
                display: none;
              }
            }
  
          }
        }
      }
  
      .add-set {
        margin-top: 30px;
  
        .mark-ipt {
          padding: 10px;
  
          .box-btn {
            width: 90px;
            line-height: 30px;
            text-align: center;
            border: 1px solid #f2f2f2;
            border-radius: 50px;
            margin: 0 5px;
          }
  
          .box-btn1 {
            border-color: #63b0ff;
            color: #63b0ff;
            background-color: #f6faff;
          }
        }
  
      }
  
    }
  
    .dialog {
      box-sizing: border-box;
  
      /deep/.el-dialog {
        min-width: 360px;
      }
  
      /deep/ .el-dialog__header {
        padding: 0;
  
        .dialogTitle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #f2f2f2;
          padding: 15px;
          margin-bottom: 10px;
  
          .left {
            font-size: 12px;
            color: #409eff;
          }
  
          .center {
            font-size: 16px;
            font-weight: bold;
          }
  
          .right {
            font-size: 16px;
            font-weight: bold;
            color: #409eff;
          }
        }
  
        .dialogSearch {
          position: relative;
          top: 20px;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          box-sizing: border-box;
          padding: 10px 30px 5px;
  
          .el-button--primary {
            height: 40px;
            margin-left: -5px;
            z-index: 1;
            font-size: 12px;
            border-radius: 0 5px 5px 0;
            border: 1px solid #3d96f2;
          }
  
          .el-button--primary:focus,
          .el-button--primary:hover,
          .el-button:active,
          .el-button:focus,
          .el-button:hover {
            color: #fff;
            border-color: #409eff;
            background-color: #409eff;
          }
  
          /deep/ .el-input__inner {
            height: 35px;
            border: 1px solid #dcdfe6;
            border-right: 0;
            border-radius: 5px 0 0 5px;
  
            &:focus,
            &:hover {
              border-color: #dcdfe6;
            }
          }
        }
      }
  
      /deep/.el-dialog__body {
        height: 400px;
        padding: 0 15px;
  
      }
  
      .container {
        height: 350px;
      }
  
      .btn {
        padding: 5px 30px;
  
        .el-button {
          width: 100%;
        }
      }
    }
  
    .serviceBrandDialog {
  
      .cell-list {
        width: 100%;
        padding: 0 !important;
  
        /deep/ .el-checkbox-group {
          width: 100%;
          position: relative;
  
          .el-checkbox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #f2f2f2;
            padding: 10px 0;
            margin-bottom: 10px;
            margin-right: 0;
  
            .dialog-title {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 12px;
            }
  
            .el-checkbox__input {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
  
      }
    }
  
    .serviceClassDialog {
  
      .cell-list {
        width: 100%;
        padding: 0 !important;
  
        /deep/ .el-radio-group {
          width: 100%;
          position: relative;
  
          .el-radio {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #f2f2f2;
            padding: 10px 0;
            margin-bottom: 10px;
            margin-right: 0;
  
            .dialog-title {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 12px;
            }
  
            .el-radio__input {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
  
      }
    }
  
  
    .footer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      background-color: #fff;
      padding: 10px 0;
  
      .el-button {
        width: 30%;
        min-width: 240px;
      }
    }
  
  </style>
  